import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { GridComponent, Column, PagerComponent, SortService, ColumnModel } from '@syncfusion/ej2-angular-grids';
import { DialogService } from '@ngneat/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ChangeEventArgs } from '@syncfusion/ej2-angular-dropdowns';

import { CompanyDetails, IBankAccounts, ICheque, IMeta } from '@core/models';
import { BankAccountService, ChequeService, CommonService, MenuService } from '@core/services';
import { CreateChequeComponent } from '../create-cheque/create-cheque.component';

@Component({
    selector: 'app-transactions',
    styleUrls: ['./transactions.component.scss'],
    templateUrl: './transactions.component.html',
    providers: [SortService],
})
export class TransactionsComponent implements OnInit, AfterViewInit {
    @ViewChild('transactionsGrid')
    gridInstance!: GridComponent;
    public bankAccounts: IBankAccounts[] = [];
    public organizations: CompanyDetails[] = [];
    public pdfUrl: SafeResourceUrl = '';
    public transactionPageSize: number = 10;
    public transactionTotalRecords: number = 0;
    public transactionPageCount: number = 0;
    public transactionPageNumber: number = 1;
    public supplierPageCount: number = 1;
    public transactions: ICheque[] = [];
    public filteredTransactions: ICheque[] = [];
    private dialog: DialogService = inject(DialogService);
    @ViewChild('pdfViewer') pdfViewer!: ElementRef;
    @ViewChild('transactionsPager')
    transactionsPager!: PagerComponent;
    public searchTerm: number | null = null;
    public paymentType: string = 'All';
    public fields: { text: string; value: string } = {
        text: 'name',
        value: 'id',
    };
    public paymentTypes: { name: string; id: string }[] = [{ id: 'All', name: 'All' }];
    public previousSearchTerm: number | null = null;
    public previousPaymentType: string = '';
    private debounceTimeout: number | null = null;

    constructor(
        private menuService: MenuService,
        private commonService: CommonService,
        private chequeService: ChequeService,
        private bankAccountService: BankAccountService,
        private sanitizer: DomSanitizer
    ) {
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    }

    ngOnInit(): void {
        this.transactionPageNumber = 1;
        this.menuService.updateMenuSelection('cheques');
        this.getPaymentTypes();
    }

    ngAfterViewInit(): void {
        ((this.gridInstance as GridComponent).columns[1] as ColumnModel).customAttributes = { class: 'currency-column' };
    }

    getPaymentTypes = () => {
        this.chequeService.getPaymentTypes().subscribe({
            next: (res: { name: string; id: string }[]) => {
                if (res) {
                    this.paymentTypes = [...this.paymentTypes, ...res];
                    this.getBankAccounts();
                }
            },
        });
    };

    getBankAccounts = () => {
        this.bankAccountService.getBankAccounts().subscribe({
            next: (res: IBankAccounts[]) => {
                this.bankAccounts = res;
                this.getTransactions();
            },
        });
    };

    getTransactions = () => {
        let query: string = `page=${this.transactionPageNumber}&size=${this.transactionPageSize}&sort=organizationUpdatedIndex-desc`;
        const paymentType: string = this.paymentType === 'All' ? '' : this.paymentType;

        this.searchTerm && (query = `chequeNumber=${this.searchTerm}`);
        paymentType && (query = query ? `${query}&paymentType=${paymentType}` : `paymentType=${paymentType}`);

        this.chequeService.getCheques(query).subscribe({
            next: (res: { content: ICheque[]; meta: IMeta }) => {
                if (res && 'content' in res && res.content) {
                    this.transactions = res.content.map((obj) => {
                        obj['bankAccountNumber'] = this.bankAccounts.find((item) => item.id === obj.bankAccount)?.account || '';
                        obj['bankAccountName'] = this.bankAccounts.find((item) => item.id === obj.bankAccount)?.name || '';
                        obj['paymentTypeName'] = this.paymentTypes.find((item) => item.id === obj.paymentType)?.name || '';
                        return obj;
                    });
                    this.filteredTransactions = this.transactions;
                    this.transactions.length === 0 && (this.transactionPageNumber = 1);
                }

                if (res && 'meta' in res && res.meta) {
                    this.transactionTotalRecords = res.meta.total;
                    this.transactionPageCount = Math.ceil(res.meta.total / this.transactionPageSize);
                }
            },
            error: () => {
                this.filteredTransactions = [];
            },
        });
    };

    changePaymentType = (paymentType: string, events: ChangeEventArgs) => {
        this.transactionPageNumber = 1;
        this.transactions = [];
        this.paymentType = (events.value as string) || '';

        this.getTransactions();
    };

    handleSearch = () => {
        if (this.previousSearchTerm === this.searchTerm && this.previousPaymentType === this.paymentType) {
            return;
        }

        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
        }

        this.debounceTimeout = setTimeout(() => {
            this.previousSearchTerm = this.searchTerm;
            this.previousPaymentType = this.paymentType;

            if (this.searchTerm) {
                const filteredTrans: ICheque[] = this.transactions.filter((obj) => {
                    return this.paymentType
                        ? obj.chequeNumber == this.searchTerm && obj.paymentType === this.paymentType
                        : obj.chequeNumber == this.searchTerm;
                });

                if (filteredTrans.length > 0) {
                    this.filteredTransactions = filteredTrans;
                } else {
                    this.transactionPageNumber = 1;
                    this.transactions = [];
                    this.getTransactions();
                }
            } else if (!this.searchTerm) {
                this.transactionPageNumber = 1;
                this.transactions = [];
                this.getTransactions();
            }
        }, 300) as unknown as number;
    };

    handlePrintCheque = (event: MouseEvent, id: string) => {
        event.stopPropagation();

        if (id) {
            this.chequeService.getChequePDF(id).subscribe({
                next: (res: { status: boolean; data: { url: string } }) => {
                    if (res && 'data' in res && res.data && 'url' in res.data && res.data.url) {
                        this.getPdfFile(res.data?.url);
                    }
                },
            });
        }
    };

    getPdfFile = (fileURL: string) => {
        this.commonService.getPDFFile(fileURL).subscribe((response: Blob) => {
            // Read the Blob data and convert it to an ArrayBuffer
            const reader = new FileReader();
            reader.onload = () => {
                const arrayBuffer = reader.result as ArrayBuffer;
                const byteArray = new Uint8Array(arrayBuffer);
                // Create a Blob with the correct MIME type
                const blob = new Blob([byteArray], {
                    type: 'application/pdf',
                });
                // Create a Blob URL and open it in a new window
                const url = URL.createObjectURL(blob);
                this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

                setTimeout(() => {
                    this.printFile();
                    this.gridInstance.refresh();
                }, 1000);
            };

            reader.readAsArrayBuffer(response);
        });
    };

    printFile = () => {
        if (this.pdfViewer) {
            const iframe = this.pdfViewer.nativeElement;
            iframe.contentWindow.print();
        }
    };

    handleToolbarClick = (type: string) => {
        switch (type) {
            case 'download':
                if (this.gridInstance) {
                    (this.gridInstance.columns[6] as Column).visible = false;
                    this.gridInstance.excelExport();
                }
                break;
            case 'print':
                if (this.gridInstance) this.gridInstance.print();
                break;
            case 'add-new':
                this.manageTransaction(false);
                break;
            default:
                console.log('No match found');
        }
    };

    manageTransaction = (isEdit: boolean, transactionID = '', event?: MouseEvent) => {
        event && event.stopPropagation();
        const dialogRef = this.dialog.open(CreateChequeComponent, {
            minWidth: '70%',
            maxWidth: '100%',
            maxHeight: '85vh',
            data: {
                isEdit,
                transactionID,
            },
        });

        dialogRef.afterClosed$.subscribe((result: { file: string } | { batchPrinting: boolean } | null | undefined) => {
            if (result && (('file' in result && result.file) || ('batchPrinting' in result && result.batchPrinting))) {
                this.transactionPageNumber = 1;
                this.transactions = [];
                this.paymentType = 'All';

                'file' in result && result.file && this.getPdfFile(result.file);
                this.getBankAccounts();
            }
        });
    };

    handlePaging = (event: number) => {
        this.transactionPageNumber = event;

        this.getTransactions();
    };

    transactionConfig = () => {
        this.gridInstance.localeObj['localeStrings'].EmptyRecord = 'There are no transactions';
    };

    convertToUserTz = (dateStr: string): string | null => {
        return this.commonService.convertToUserTz(dateStr);
    };
}

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AnimationSettingsModel, SpinSettingsModel } from '@syncfusion/ej2-splitbuttons';

import { Register, IRegisterPayload, EToasterStatus } from '@core/models';
import { Constant } from '@core/constants';
import { AuthService, ChequeService, EmployeeService, OrganizationService, ToasterService } from '@core/services';
import { MustMatch } from '@core/helpers';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-register',
    styleUrls: ['./register.component.scss'],
    templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {
    public registerActionMessage: string = '';
    public showSuccessMessage: boolean = false;
    public showErrorMessage: boolean = false;
    public loading: boolean = false;
    public submitted: boolean = false;
    public slideRight: AnimationSettingsModel = { effect: 'SlideRight' };
    public spinCenter: SpinSettingsModel = { position: 'Center' };
    public form!: FormGroup;
    public isPingSuccessful = false;

    constructor(
        private constant: Constant,
        protected cd: ChangeDetectorRef,
        protected router: Router,
        private orgService: OrganizationService,
        private employeeService: EmployeeService,
        private chequeService: ChequeService,
        private authService: AuthService,
        private toasterService: ToasterService,
        private formBuilder: FormBuilder
    ) {
        this.form = this.formBuilder.group(
            {
                name: [null, [Validators.required]],
                email: [null, [Validators.required, Validators.pattern(this.constant.EMAIL_VALIDATION_PATTERN)]],
                reEmail: [null, [Validators.required, Validators.pattern(this.constant.EMAIL_VALIDATION_PATTERN)]],
                middleName: ['', []],
                lastName: [null, [Validators.required]],
                firstName: [null, [Validators.required]],
            },
            {
                validators: MustMatch('email', 'reEmail'),
            }
        );
    }

    ngOnInit(): void {
        this.handleColdStart();
    }

    get registerFormF(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    handleColdStart(): void {
        const coldStartRequests$ = forkJoin([
            this.orgService.pingOrganization(),
            this.employeeService.pingEmployee(),
            this.chequeService.pingTransaction(),
            this.authService.pingUser(),
        ]);
        coldStartRequests$.subscribe({
            next: (data) => {
                this.isPingSuccessful = true;
            },
            error: (err) => {
                console.log(err);
                this.isPingSuccessful = false;
            },
        });
    }

    register(): void {
        this.submitted = true;
        const payload = this.createPayload();
        this.loading = true;

        if (!this.isPingSuccessful) {
            console.error('Cold start failed. Please try again!');
        }

        this.orgService.createOrganization(payload).subscribe({
            next: () => {
                this.showSuccessMessage = true;
                this.registerActionMessage = this.constant.REGISTER_MSG.SUCCESS;
                this.loading = false;
                this.form.reset();
                this.submitted = false;
            },
            error: () => {
                this.showErrorMessage = true;
                this.registerActionMessage = this.constant.REGISTER_MSG.FAILURE;
                this.showToast(EToasterStatus.DANGER, this.registerActionMessage);
                this.loading = false;
            },
        });
    }

    createPayload(): IRegisterPayload {
        const user: Register = this.form.getRawValue();
        const name = {
            first: (user || {}).firstName || '',
            middle: (user || {}).middleName || '',
            last: (user || {}).lastName || '',
        };

        const registerData = {
            email: (user || {}).email || '',
            name: name,
        };

        const admin = [];
        admin.push(registerData);

        const actionPayload = {
            name: (user || {}).name || '',
            admins: admin,
        };
        return actionPayload;
    }

    showToast(title: EToasterStatus, message: string) {
        this.toasterService.showToast(title, message);
    }
}

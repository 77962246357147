import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { CommonService } from '@core/services';
import { CompanyDetails, IAddress, IECHQAddress, IOrganization, IRegisterPayload } from '@core/models';
import { Constant } from '@core/constants';

@Injectable({
    providedIn: 'root',
})
export class OrganizationService {
    public baseURL = '';

    constructor(private http: HttpClient, private constant: Constant, private commonService: CommonService) {
        this.baseURL = this.commonService.getBaseURL(constant.SERVICES.ORGANIZATION);
    }

    createOrganization(payload: IRegisterPayload): Observable<IOrganization> {
        const httpOptions = this.commonService.getHttpOptions();
        const url: string = this.constant.API_URLS.CREATE_ORGANIZATION;
        return this.http.post<IOrganization>(this.baseURL + url, payload, httpOptions);
    }

    updateOrganization(id: string, payload: CompanyDetails): Observable<CompanyDetails> {
        const httpOptions = this.commonService.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_ORGANIZATION_BY_ID(id);
        return this.http.put<CompanyDetails>(this.baseURL + url, payload, httpOptions);
    }

    getOrganizationInfo = (id: string) => {
        const httpOptions = this.commonService.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_ORGANIZATION_BY_ID(id);
        return this.http.get<IOrganization>(this.baseURL + url, httpOptions);
    };

    getOrganizationAddress(id: string): Observable<IECHQAddress> {
        const httpOptions = this.commonService.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_ORGANIZATION_ADDRESS(id);
        return this.http.get<IECHQAddress>(this.baseURL + url, httpOptions);
    }

    updateOrganizationAddress(id: string, payload: IECHQAddress): Observable<IECHQAddress> {
        const httpOptions = this.commonService.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_ORGANIZATION_ADDRESS(id);
        return this.http.put<IECHQAddress>(this.baseURL + url, payload, httpOptions);
    }

    pingOrganization = () => {
        const httpOptions = this.commonService.getHttpOptions();
        const url: string = this.constant.API_URLS.PING_ORGANIZATION;
        return this.http.get(this.baseURL + url, httpOptions);
    };
}

<div class="batch-printing">
    <div class="batch-printing__body">
        <div class="batch-printing__header">
            <div class="batch-printing__title">Batch Printing</div>

            <div class="batch-printing__action">
                <ejs-dropdownlist
                    id="bankAccount"
                    name="bankAccount"
                    [(ngModel)]="bankAccount"
                    (ngModelChange)="handleBankAccountChange(bankAccount)"
                    [dataSource]="bankAccounts"
                    placeholder="Select Bank"
                    [fields]="fields"
                    [showClearButton]="true"
                />

                <ejs-dropdownlist
                    id="batchStatus"
                    name="batchStatus"
                    [(ngModel)]="batchStatus"
                    (ngModelChange)="handleBatchStatusChange(batchStatus)"
                    [dataSource]="batchStatuses"
                    placeholder="Select Status"
                    [showClearButton]="true"
                />

                <button
                    [ngClass]="['e-btn e-outline e-success']"
                    id="createBatchBtn"
                    content="Start Batch Printing"
                    (click)="startBatchPrinting()"
                >
                    Create new batch
                </button>
            </div>
        </div>

        <ejs-grid
            [dataSource]="filteredCheques"
            #chequeList
            id="chequeList"
            class="batch-printing__grid"
            rowHeight="45"
            [allowPaging]="true"
            [allowSorting]="true"
            [pageSettings]="pageSettings"
            (rowSelected)="rowSelectionChanged()"
            (rowDeselected)="rowSelectionChanged()"
            (rowDataBound)="rowDataBound($event)"
        >
            <e-columns>
                <e-column field="bankName" headerText="Bank Name" />
                <e-column field="name" headerText="Name" />
                <e-column field="updatedAt" headerText="Updated At">
                    <ng-template #template let-data>
                        {{ convertToUserTz(data.updatedAt) ? convertToUserTz(data.updatedAt) : '' }}
                    </ng-template>
                </e-column>
                <e-column field="status" headerText="Status">
                    <ng-template #template let-data>
                        <a
                            href="javascript:void(0)"
                            [ngClass]="['batch-printing__status', getStatusClass(data.status)]"
                            *ngIf="data.status === 'Completed with errors' || data.status === 'Completed'"
                            (click)="getBatchDetailsAndPrint($event, data?.id)"
                        >
                            {{ data.status }}
                        </a>

                        <div
                            [ngClass]="['batch-printing__status', getStatusClass(data.status)]"
                            *ngIf="data.status !== 'Completed with errors' && data.status !== 'Completed'"
                        >
                            {{ data.status }}
                        </div>
                    </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>

        <iframe #pdfViewer [src]="pdfUrl" width="100%" height="600" class="pdf-preview"></iframe>
    </div>
</div>

import { AfterViewInit, Component, OnInit, ViewChild, inject } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { ColumnModel, GridComponent, PageSettingsModel, RowDataBoundEventArgs } from '@syncfusion/ej2-angular-grids';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import {
    EToasterStatus,
    IBankAccounts,
    IBatchPrintingPayload,
    ICheque,
    IECHQAddress,
    IExpenseAccounts,
    IMeta,
    ISupplier,
} from '@core/models';
import {
    BankAccountService,
    BatchPrintingService,
    ChequeService,
    CommonService,
    ExpenseAccountService,
    SupplierService,
    ToasterService,
} from '@core/services';

@Component({
    selector: 'app-batch-printing-dialog',
    templateUrl: './batch-printing-dialog.component.html',
    styleUrls: ['./batch-printing-dialog.component.scss'],
})
export class BatchPrintingDialogComponent implements OnInit, AfterViewInit {
    public ref: DialogRef<void, boolean | { crudOperation: boolean }> = inject(DialogRef);
    @ViewChild('chequeList')
    gridInstance!: GridComponent;
    public bankAccount = '';
    public bankAccounts: IBankAccounts[] = [];
    public isValidBankDetails: boolean = false;
    public pageSettings!: PageSettingsModel;
    public pageSizes: number[] = [10, 25, 50];
    public cheques: ICheque[] = [];
    public suppliers: ISupplier[] = [];
    public batchTransactionID: string = '';
    public bankAccountAddress: IECHQAddress | null = null;
    public expenseAccounts: IExpenseAccounts[] = [];
    public paymentTypes: { name: string; id: string }[] = [];
    public bankDetailsForm: FormGroup = new FormGroup({
        bankAccount: new FormControl<string>('', [Validators.required]),
        chequeNumber: new FormControl<number | null>(null, []),
    });
    public fields: { text: string; value: string } = {
        text: 'name',
        value: 'id',
    };

    constructor(
        private toasterService: ToasterService,
        private bankAccountService: BankAccountService,
        private expenseAccountService: ExpenseAccountService,
        private chequeService: ChequeService,
        private supplierService: SupplierService,
        private batchPrintService: BatchPrintingService,
        private commonService: CommonService,
        private fb: FormBuilder
    ) {
        this.bankDetailsForm = this.fb.group({
            bankAccount: ['', [Validators.required]],
            chequeNumber: [{ value: null, disabled: true }, []],
        });
    }

    ngOnInit(): void {
        this.getSuppliers();
        this.getBankAccounts();
        this.getPaymentTypes();
    }

    ngAfterViewInit(): void {
        this.pageSettings = { pageCount: 3, pageSize: this.pageSizes[0] };
    }

    get bankDetailsFormF(): { [key: string]: AbstractControl } {
        return this.bankDetailsForm.controls;
    }

    cancel = () => {
        this.ref.close(false);
    };

    showToast(title: EToasterStatus, message: string) {
        this.toasterService.showToast(title, message);
    }

    isPrintDisabled = (): boolean => {
        return this.gridInstance && this.gridInstance.getSelectedRecords().length === 0;
    };

    customAggregateFn = (): number => {
        let sum = 0;
        (this.gridInstance.getSelectedRecords() as unknown as ICheque[]).forEach((data) => {
            sum += data['amount'];
        });
        return sum;
    };

    convertToUserTz = (dateStr: string): string | null => {
        return this.commonService.convertToUserTz(dateStr);
    };

    getPaymentTypes = () => {
        this.chequeService.getPaymentTypes().subscribe({
            next: (res: { name: string; id: string }[]) => {
                if (res) {
                    this.paymentTypes = res;
                }
            },
        });
    };

    getCheques = () => {
        const qString = `bankId=${this.bankAccount}&batchPrinting=true&sort=organizationUpdatedIndex-desc`;
        this.chequeService.getCheques(qString).subscribe({
            next: (res: { content: ICheque[]; meta: IMeta }) => {
                if (res && 'content' in res && res.content) {
                    this.cheques = res.content.map((obj) => {
                        const supplier = this.suppliers.find((item) => item.id === obj.supplierId);
                        obj['supplierName'] = supplier ? `${supplier.name?.first}, ${supplier.name?.last}` : '';
                        obj['expenseAccountNumber'] = this.expenseAccounts.find((item) => item.id === obj.expenseAccount)?.account || '';
                        obj['paymentTypeName'] = this.paymentTypes.find((item) => item.id === obj.paymentType)?.name || '';
                        obj['supplierCompanyName'] = obj.supplier?.companyName || '';
                        return obj;
                    });

                    ((this.gridInstance as GridComponent).columns[4] as ColumnModel).customAttributes = { class: 'currency-column' };
                    (((this.gridInstance as GridComponent).aggregates[0].columns || [])[0] as ColumnModel).customAttributes = {
                        class: 'currency-column',
                    };
                }
            },
        });
    };

    getExpenseAccounts = () => {
        this.expenseAccountService.getExpenseAccounts().subscribe({
            next: (res: IExpenseAccounts[]) => {
                this.expenseAccounts = res;
                this.getCheques();
            },
        });
    };

    getBankAccounts = () => {
        this.bankAccountService.getBankAccounts().subscribe({
            next: (res: IBankAccounts[]) => {
                this.bankAccounts = res;
            },
        });
    };

    getSuppliers = () => {
        this.supplierService.getSuppliers('').subscribe({
            next: (res: { content: ISupplier[]; meta: IMeta }) => {
                if (res && 'content' in res && res.content) {
                    this.suppliers = res.content;
                }
            },
        });
    };

    startBatchPrinting = () => {
        if (this.bankAccount) {
            const selection = this.gridInstance.getSelectedRecords() as unknown as ICheque[];
            if (selection.length > 0) {
                const payload: IBatchPrintingPayload = {
                    name: 'default',
                    bankId: this.bankAccount,
                    transactions: selection.map((obj) => {
                        return {
                            id: obj.id,
                        };
                    }),
                };
                this.batchPrintService.CreateBatchPrinting(payload).subscribe({
                    next: (res) => {
                        this.batchTransactionID = res?.data || '';
                        this.ref.close({ crudOperation: true });
                    },
                });
            }
        }
    };

    public rowSelectionChanged() {
        const selection = this.gridInstance.getSelectedRecords();
        this.gridInstance.aggregateModule.refresh(selection);
    }

    public rowDataBound(args: RowDataBoundEventArgs): void {
        const rowData = args.data as ICheque;

        args.isSelectable = !(rowData?.chequeNumber && rowData?.chequeId);
    }

    // isPrintDisabled = (): boolean => {
    //     return !this.bankDetailsForm?.get('bankAccount')?.value || !this.bankDetailsForm?.get('chequeNumber')?.value;
    // };

    changeBankAccount = (bankAccount: string) => {
        this.bankAccount = bankAccount;

        if (bankAccount) {
            this.getExpenseAccounts();
            this.getBankAccountByID(bankAccount);
        } else {
            this.cheques = [];
        }
    };

    getBankAccountByID = (id: string) => {
        this.bankAccountService.getBankAccountByID(id).subscribe({
            next: (res: IBankAccounts) => {
                if (res && 'address' in res && res.address) {
                    this.bankAccountAddress = res.address;
                }

                this.bankDetailsForm.patchValue({
                    chequeNumber: res.chequeSequence,
                });
            },
        });
    };
}

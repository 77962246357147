<div class="cheques">
    <div class="cheques__toolbar toolbar">
        <div class="toolbar__left">
            <ejs-dropdownlist
                id="paymentType"
                name="paymentType"
                [(ngModel)]="paymentType"
                (change)="changePaymentType(paymentType, $event)"
                [dataSource]="paymentTypes"
                placeholder="Payment Type"
                floatLabelType="Auto"
                [fields]="fields"
            />

            <ejs-numerictextbox
                cssClass="e-style"
                placeholder="Search..."
                floatLabelType="Auto"
                [(ngModel)]="searchTerm"
                (ngModelChange)="handleSearch()"
                decimals="0"
                format="#"
                floatLabelType="Auto"
            />
        </div>

        <button
            [ngClass]="['toolbar__add-new e-btn e-small e-outline e-success']"
            id="addNewBtn"
            content="Add New"
            (click)="handleToolbarClick('add-new')"
        >
            Add New
        </button>
    </div>

    <div class="cheques__body">
        <ejs-grid
            #transactionsGrid
            id="transactionsGrid"
            [dataSource]="filteredTransactions"
            rowHeight="45"
            [allowSorting]="true"
            (load)="transactionConfig()"
        >
            <e-columns>
                <e-column field="transactionDate" headerText="Transaction Date">
                    <ng-template #template let-data>
                        {{ convertToUserTz(data.transactionDate) ? convertToUserTz(data.transactionDate) : '' }}
                    </ng-template>
                </e-column>
                <e-column field="amount" headerText="Amount" textAlign="right">
                    <ng-template #template let-data>
                        {{ data.amount | currency }}
                    </ng-template>
                </e-column>
                <e-column field="bankAccountName" headerText="Bank Name" />
                <e-column field="bankAccountNumber" headerText="Bank Account" />
                <e-column field="paymentTypeName" headerText="Payment Type" />
                <e-column field="chequeNumber" headerText="Cheque Number">
                    <ng-template #template let-data>
                        <a href="javascript:void(0)" class="cheques__link" *ngIf="data.paymentTypeName !== 'Cheque'"> - </a>

                        <a
                            href="javascript:void(0)"
                            class="cheques__link"
                            (click)="handlePrintCheque($event, data.chequeId)"
                            *ngIf="data.paymentTypeName === 'Cheque'"
                        >
                            {{ data.chequeNumber }}
                        </a>
                    </ng-template>
                </e-column>

                <e-column field="" headerText="Action" width="120">
                    <ng-template #template let-data>
                        <div class="action-container">
                            <a
                                href="javascript:void(0)"
                                class="cheques__action--link edit"
                                (click)="manageTransaction(true, data?.id, $event)"
                            >
                                <span class="material-icons-outlined"> edit </span>
                            </a>
                        </div>
                    </ng-template>
                </e-column>
            </e-columns>
        </ejs-grid>

        <ejs-pager
            *ngIf="transactions.length > 0"
            #transactionsPager
            id="transactionsPager"
            [totalRecordsCount]="transactionTotalRecords"
            [pageCount]="transactionPageCount"
            [pageSize]="transactionPageSize"
            (currentPageChange)="handlePaging($event)"
        />
    </div>

    <iframe #pdfViewer [src]="pdfUrl" width="100%" height="600" class="pdf-preview"></iframe>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CommonService } from '@core/services';
import { ILoginResponse, IChangePasswordPayload, IProfile, IResetPasswordPayload } from '@core/models';
import { Constant } from '@core/constants';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    baseURL = '';

    constructor(private http: HttpClient, private constant: Constant, private commonService: CommonService) {
        this.baseURL = this.commonService.getBaseURL(constant.SERVICES.USER);
    }

    isLoggedIn(): boolean {
        const token: string = this.commonService.getToken();
        return !!token;
    }

    getHttpOptionsUserVerify(token: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            }),
        };
        return httpOptions;
    }

    getLoginHttpOptions(email: string, password: string) {
        return {
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: 'Basic ' + btoa(email + ':' + password),
            }),
        };
    }

    login(email: string, password: string): Observable<ILoginResponse> {
        const httpOptions = this.getLoginHttpOptions(email, password);
        const url = this.constant.API_URLS.LOGIN;
        return this.http.post<ILoginResponse>(this.baseURL + url, { email, password }, httpOptions);
    }

    requestPassword(email: string) {
        const httpOptions = this.commonService.getHttpOptions();
        const url = this.constant.API_URLS.FORGOT_PASSWORD;
        return this.http.post(this.baseURL + url, { email }, httpOptions);
    }

    changePassword(id: string, payload: IChangePasswordPayload) {
        const httpOptions = this.commonService.getHttpOptions();
        const url = this.constant.API_URLS.CHANGE_PASSWORD(id);
        return this.http.post(this.baseURL + url, payload, httpOptions);
    }

    verifyUser(password: string, token: string) {
        const httpOptions = this.getHttpOptionsUserVerify(token);
        const url = this.constant.API_URLS.VERIFY_USER;
        return this.http.post(this.baseURL + url, { password }, httpOptions);
    }

    resetPassword(payload: IResetPasswordPayload) {
        const httpOptions = this.commonService.getHttpOptions();
        const url = this.constant.API_URLS.RESET_PASSWORD;
        return this.http.post(this.baseURL + url, payload, httpOptions);
    }

    getUserInformation = (id: string): Observable<IProfile> => {
        const httpOptions = this.commonService.getHttpOptions();
        const url = this.constant.API_URLS.GET_USER(id);
        return this.http.get<IProfile>(this.baseURL + url, httpOptions);
    };

    pingUser = () => {
        const httpOptions = this.commonService.getHttpOptions();
        const url: string = this.constant.API_URLS.PING_USER;
        return this.http.get(this.baseURL + url, httpOptions);
    };
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Constant } from '@core/constants';
import { Observable } from 'rxjs';
import { IECHQAddress, IMeta, ISupplier, ISupplierPayload, ISupplierTransactions } from '../models';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class SupplierService {
    public baseURL = '';

    constructor(
        private http: HttpClient,
        private constant: Constant,
        private commonService: CommonService,
        private userService: UserService
    ) {
        this.baseURL = this.commonService.getBaseURL(constant.SERVICES.TRANSACTION);
    }

    getHttpOptions() {
        const token: string = this.commonService.getToken();
        const orgId: string = this.userService.organizationId;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                orgid: orgId,
            }),
        };
        return httpOptions;
    }

    getSuppliers(qString: string): Observable<{ content: ISupplier[]; meta: IMeta }> {
        const httpOptions = this.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_SUPPLIERS(qString);
        return this.http.get<{ content: ISupplier[]; meta: IMeta }>(this.baseURL + url, httpOptions);
    }

    updateAddress = (supplierID: string, payload: IECHQAddress): Observable<IECHQAddress> => {
        const httpOptions = this.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_SUPPLIERS_ADDRESS(supplierID);
        return this.http.patch<IECHQAddress>(this.baseURL + url, payload, httpOptions);
    };

    getSupplierAddress = (supplierID: string): Observable<IECHQAddress> => {
        const httpOptions = this.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_SUPPLIERS_ADDRESS(supplierID);
        return this.http.get<IECHQAddress>(this.baseURL + url, httpOptions);
    };

    createSupplier = (payload: ISupplierPayload): Observable<ISupplier> => {
        const httpOptions = this.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_SUPPLIERS('');
        return this.http.post<ISupplier>(this.baseURL + url, payload, httpOptions);
    };

    updateSupplier = (supplierID: string, payload: ISupplierPayload): Observable<ISupplier> => {
        const httpOptions = this.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_SUPPLIER_BY_ID(supplierID);
        return this.http.put<ISupplier>(this.baseURL + url, payload, httpOptions);
    };

    getSupplierInfo = (supplierID: string): Observable<ISupplier> => {
        const httpOptions = this.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_SUPPLIER_BY_ID(supplierID);
        return this.http.get<ISupplier>(this.baseURL + url, httpOptions);
    };

    deleteSupplier = (id: string) => {
        const httpOptions = this.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_SUPPLIER_BY_ID(id);
        return this.http.delete<{ status: boolean }>(this.baseURL + url, httpOptions);
    };

    getFilteredTransactions(qString: string): Observable<{ content: ISupplierTransactions[]; meta: IMeta }> {
        const httpOptions = this.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_CHEQUES(qString);
        return this.http.get<{ content: ISupplierTransactions[]; meta: IMeta }>(this.baseURL + url, httpOptions);
    }
}

<div class="batch-printing-dialog">
    <div class="batch-printing-dialog__title">New batch request</div>

    <div class="batch-printing-dialog__body">
        <form id="bankDetailsForm" [formGroup]="bankDetailsForm" class="bank-details form">
            <div class="form__group required">
                <ejs-dropdownlist
                    id="bankAccount"
                    name="bankAccount"
                    [(ngModel)]="bankAccount"
                    (ngModelChange)="changeBankAccount(bankAccount)"
                    formControlName="bankAccount"
                    [dataSource]="bankAccounts"
                    placeholder="Bank Details"
                    floatLabelType="Always"
                    [fields]="fields"
                />

                <ng-container *ngIf="bankDetailsFormF['bankAccount'].touched && bankDetailsFormF['bankAccount'].errors">
                    <p class="caption status-danger" *ngIf="bankDetailsFormF['bankAccount'].errors['required']">
                        Bank account is required.
                    </p>
                </ng-container>

                <div class="form__group--address address" *ngIf="bankAccountAddress">
                    <ng-container *ngIf="bankAccountAddress; else addressEmpty">
                        <div class="address__text">
                            {{ bankAccountAddress.firstLine }}
                        </div>

                        <div class="address__text">
                            <span *ngIf="bankAccountAddress.lastLine">{{ bankAccountAddress.lastLine }}, &nbsp;</span>
                            {{ bankAccountAddress.zipCode }}
                        </div>

                        <div class="address__text">
                            <span *ngIf="bankAccountAddress.city">{{ bankAccountAddress.city }}, &nbsp;</span>
                            {{ bankAccountAddress.state }}
                        </div>

                        <div class="address__text">
                            {{ bankAccountAddress.country }}
                        </div>
                    </ng-container>

                    <ng-template #addressEmpty>-</ng-template>
                </div>
            </div>

            <div class="form__group required">
                <ejs-textbox placeholder="Cheque Number" floatLabelType="Always" formControlName="chequeNumber" />

                <ng-container *ngIf="bankDetailsFormF['chequeNumber'].touched && bankDetailsFormF['chequeNumber'].errors">
                    <p class="caption status-danger" *ngIf="bankDetailsFormF['chequeNumber'].errors['required']">
                        Cheque number is required.
                    </p>
                </ng-container>
            </div>
        </form>

        <ejs-grid
            [dataSource]="cheques"
            #chequeList
            id="chequeList"
            class="batch-printing-dialog__grid"
            rowHeight="45"
            [allowPaging]="true"
            [allowSorting]="true"
            [pageSettings]="pageSettings"
            (rowSelected)="rowSelectionChanged()"
            (rowDeselected)="rowSelectionChanged()"
            (rowDataBound)="rowDataBound($event)"
        >
            <e-columns>
                <e-column type="checkbox" width="50" headerText="Print?"></e-column>
                <e-column field="supplierCompanyName" headerText="Supplier Name" />
                <e-column field="chequeNumber" headerText="Cheque Number">
                    <ng-template #template let-data>
                        <a href="javascript:void(0)" [ngClass]="['batch-printing__link', data.chequeId ? '' : 'disabled']">
                            {{ data.chequeId ? data.chequeNumber : '' }}
                        </a>
                    </ng-template>
                </e-column>
                <e-column field="transactionDate" headerText="Transaction Date">
                    <ng-template #template let-data>
                        {{ convertToUserTz(data.transactionDate) ? convertToUserTz(data.transactionDate) : '' }}
                    </ng-template>
                </e-column>
                <e-column field="amount" headerText="Amount" format="C2" textAlign="right" />
                <e-column field="paymentTypeName" headerText="Payment Type" />
                <e-column field="memo" headerText="Memo" />
            </e-columns>

            <e-aggregates>
                <e-aggregate>
                    <e-columns>
                        <e-column field="amount" format="c2" type="Custom" [customAggregate]="customAggregateFn">
                            <ng-template #footerTemplate let-data> Total Amount: {{ data.Custom }} </ng-template>
                        </e-column>
                    </e-columns>
                </e-aggregate>
            </e-aggregates>
        </ejs-grid>

        <div class="batch-printing-dialog__action">
            <button
                [ngClass]="['e-btn e-outline', isPrintDisabled() ? '' : 'e-success']"
                id="cancelBtn"
                content="Start Batch Printing"
                (click)="startBatchPrinting()"
                [disabled]="isPrintDisabled()"
            >
                Create
            </button>

            <button [ngClass]="['e-btn e-outline e-danger']" id="cancelBtn" content="Cancel" (click)="cancel()">Cancel</button>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

import { Constant } from '@core/constants';
import { UserService, CommonService } from '@core/services';
import { AuthState, IEmployee } from '@core/models';

@Injectable({
    providedIn: 'root',
})
export class StartupService {
    baseUrl = '';
    navigationData: { loggedInStatus: boolean; isTokenValid: boolean } = {
        loggedInStatus: false,
        isTokenValid: false,
    };

    constructor(
        private http: HttpClient,
        private constant: Constant,
        private userService: UserService,
        private commonService: CommonService
    ) {
        this.baseUrl = this.commonService.getBaseURL(constant.SERVICES.EMPLOYEE);
    }

    getToken(): string {
        const userInfoStr: string = localStorage.getItem('auth') || '';
        const userInfo = JSON.parse(userInfoStr);
        return ((userInfo || {}).user || {}).token || '';
    }

    getHttpOptions() {
        const token: string = this.getToken();
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            }),
        };
        return httpOptions;
    }

    load = (): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            if (location.pathname !== '/verify' && location.pathname !== '/resetpassword') {
                const userInfoStr: string = localStorage.getItem('auth') || '';
                const userInfo: AuthState = JSON.parse(userInfoStr);
                const httpOptions = this.getHttpOptions();
                const url: string = this.baseUrl + this.constant.API_URLS.GET_EMPLOYEE;

                if (!!userInfo && userInfo.isAuthenticated) {
                    firstValueFrom(this.http.get(url, httpOptions))
                        .then((data) => {
                            this.userService.setUserDetails(data as IEmployee);
                            this.navigationData = {
                                loggedInStatus: true,
                                isTokenValid: true,
                            };
                            resolve(true); // API resolved
                        })
                        .catch((err) => {
                            if (err instanceof HttpErrorResponse && (err.status === 401 || err.status === 403)) {
                                // Handle 401 error
                                this.navigationData = {
                                    loggedInStatus: true,
                                    isTokenValid: false,
                                };
                                resolve(false); // API rejected or encountered an error
                            } else {
                                // Handle other errors
                                this.navigationData = {
                                    loggedInStatus: true,
                                    isTokenValid: false,
                                };
                                resolve(false); // API rejected or encountered an error
                            }
                        });
                } else {
                    this.navigationData = {
                        loggedInStatus: false,
                        isTokenValid: false,
                    };
                    resolve(false); // User not authenticated
                }
            } else {
                resolve(true); // Not logged in but allow to proceed
            }
        });
    };

    get getNavigationData() {
        return this.navigationData;
    }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CommonService, UserService } from '@core/services';
import { ITax } from '@core/models';
import { Constant } from '@core/constants';

@Injectable({
    providedIn: 'root',
})
export class TaxSetupService {
    public baseURL = '';

    constructor(
        private http: HttpClient,
        private constant: Constant,
        private commonService: CommonService,
        private userService: UserService
    ) {
        this.baseURL = this.commonService.getBaseURL(constant.SERVICES.TRANSACTION);
    }

    getHttpOptions() {
        const token: string = this.commonService.getToken();
        const orgId: string = this.userService.organizationId;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                orgid: orgId,
            }),
        };
        return httpOptions;
    }

    getTaxes(): Observable<ITax[]> {
        const httpOptions = this.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_TAXES;
        return this.http.get<ITax[]>(this.baseURL + url, httpOptions);
    }

    createNewTax(payload: ITax): Observable<ITax> {
        const httpOptions = this.getHttpOptions();
        const url: string = this.constant.API_URLS.GET_TAXES;
        return this.http.post<ITax>(this.baseURL + url, payload, httpOptions);
    }
}
